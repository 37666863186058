<template lang="pug">
  .refresh-button(@click="onRefreshClick")
    img(src="@/assets/img/ui/refresh.svg").pointer
    span(v-show="lastRefreshText") Refreshed {{ lastRefreshText }} ago
</template>

<script>
export default {
  data: () => ({
    lastRefreshTime: null,
    lastRefreshText: null,
  }),
  methods: {
    onRefreshClick() {
      this.updateTime()
      this.$emit('click', {});
    },
    updateTime() {
      this.lastRefreshTime = this.$dates();
    },
    convertTime() {
      this.lastRefreshText = this.$dates().from(this.lastRefreshTime, true)
    },
    startUpdater() {
      this.updater = setInterval(this.convertTime, 1000)
    },
    stopUpdater() {
      if (this.updater) {
        clearInterval(this.updater)
        this.updater = null
      }
    }
  },
  created() {
    this.updateTime()
  },
  mounted() {
    this.startUpdater()
  },
  beforeDestroy() {
    this.stopUpdater()
  }
}
</script>

<style lang="scss" scoped>
.refresh-button {
  font-size: 14px;
  font-weight: 500;
  color: $border-secondary;
  display: flex;
  align-items: center;

  @include media("<=tablet") {
    min-width: 150px;
    padding-right: 5px;
  }

  img {
    height: 24px;

    &:hover {
      transition: all ease-in-out;
      transition-duration: 0.5s;
      transform: rotateZ(-360deg);
    }
  }

  span {
    margin-left: 14px;

    @include media("<=tablet") {
      font-size: 12px;
    }
  }
}
</style>
